var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          staticClass:
            "\n      min-height-300\n      border-radius-xl\n      overflow-hidden\n      position-relative\n      mx-3\n    ",
          style:
            "background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80'); background-position: 50%; background-size: cover;",
        },
        [_c("span", { staticClass: "mask bg-gradient-primary opacity-6" })]
      ),
      _c(
        "v-card",
        {
          staticClass:
            "shadow px-4 py-4 mx-9 overflow-hidden border-radius-xl mt-n16",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "shadow border-radius-lg",
                      attrs: { width: "74", height: "74" },
                    },
                    [
                      _c("img", {
                        staticClass: "border-radius-lg",
                        attrs: {
                          src: require("@/assets/img/bruce-mars.jpg"),
                          alt: "Avatar",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("v-col", { staticClass: "my-auto", attrs: { cols: "auto" } }, [
                _c("div", { staticClass: "h-100" }, [
                  _c(
                    "h5",
                    { staticClass: "mb-1 text-h5 text-typo font-weight-bold" },
                    [_vm._v(" Richard Devis ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "mb-0 font-weight-light text-body text-sm" },
                    [_vm._v(" CEO / Co-Founder ")]
                  ),
                ]),
              ]),
              _c(
                "v-col",
                {
                  staticClass: "my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3",
                  attrs: { lg: "4", md: "6" },
                },
                [
                  _c(
                    "v-tabs",
                    { attrs: { "background-color": "transparent", right: "" } },
                    [
                      _c("v-tabs-slider"),
                      _c(
                        "v-tab",
                        { attrs: { ripple: false, href: "#tab-1" } },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "text-dark me-1",
                              attrs: {
                                width: "16px",
                                height: "16px",
                                viewBox: "0 0 42 42",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(-2319.000000, -291.000000)",
                                        fill: "#FFFFFF",
                                        "fill-rule": "nonzero",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(1716.000000, 291.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(603.000000, 0.000000)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z",
                                                },
                                              }),
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z",
                                                  opacity: "0.7",
                                                },
                                              }),
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z",
                                                  opacity: "0.7",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "ms-1" }, [_vm._v("App")]),
                        ]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { ripple: false, href: "#tab-2" } },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "text-dark me-1",
                              attrs: {
                                width: "16px",
                                height: "16px",
                                viewBox: "0 0 40 44",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              },
                            },
                            [
                              _c("title", [_vm._v("document")]),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(-1870.000000, -591.000000)",
                                        fill: "#FFFFFF",
                                        "fill-rule": "nonzero",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(1716.000000, 291.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(154.000000, 300.000000)",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z",
                                                  opacity: "0.603585379",
                                                },
                                              }),
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "ms-1" }, [
                            _vm._v("Messages"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { ripple: false, href: "#tab-3" } },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "text-dark me-1",
                              attrs: {
                                width: "16px",
                                height: "16px",
                                viewBox: "0 0 40 40",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              },
                            },
                            [
                              _c("title", [_vm._v("settings")]),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(-2020.000000, -442.000000)",
                                        fill: "#FFFFFF",
                                        "fill-rule": "nonzero",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(1716.000000, 291.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(304.000000, 151.000000)",
                                              },
                                            },
                                            [
                                              _c("polygon", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  opacity: "0.596981957",
                                                  points:
                                                    "18.0883333 15.7316667 11.1783333 8.82166667 13.3333333 6.66666667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 8.82166667 11.1783333 15.315 17.6716667",
                                                },
                                              }),
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M31.5666667,23.2333333 C31.0516667,23.2933333 30.53,23.3333333 30,23.3333333 C29.4916667,23.3333333 28.9866667,23.3033333 28.48,23.245 L22.4116667,30.7433333 L29.9416667,38.2733333 C32.2433333,40.575 35.9733333,40.575 38.275,38.2733333 L38.275,38.2733333 C40.5766667,35.9716667 40.5766667,32.2416667 38.275,29.94 L31.5666667,23.2333333 Z",
                                                  opacity: "0.596981957",
                                                },
                                              }),
                                              _c("path", {
                                                staticClass: "color-background",
                                                attrs: {
                                                  d: "M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.2366667,39.1133333 12.6033333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "ms-1" }, [
                            _vm._v("Settings"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-card", { staticClass: "overflow-scroll" }, [
                    _c(
                      "div",
                      { staticClass: "py-4 d-flex" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { lg: "1", md: "2", sm: "3", cols: "4" },
                          },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "shadow-sm",
                                attrs: { size: "58" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "material-icons-round" },
                                  [_vm._v("add")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 text-sm text-body font-weight-light mt-2",
                              },
                              [_vm._v(" Add story ")]
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.stories, function (avatar) {
                          return _c(
                            "v-col",
                            {
                              key: avatar.user,
                              staticClass: "text-center",
                              attrs: { lg: "1", md: "2", sm: "3", cols: "4" },
                            },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass:
                                    "border border-primary px-1 py-1",
                                  attrs: { size: "58" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: avatar.image,
                                      width: "50",
                                      height: "50",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 text-sm text-body font-weight-light mt-2",
                                },
                                [_vm._v(" " + _vm._s(avatar.user) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { lg: "8", cols: "12" } },
                [
                  _c("v-card", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-bottom d-flex align-center px-4 py-4",
                      },
                      [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-decoration-none",
                              attrs: { href: "javascript:;" },
                            },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "rounded-circle",
                                  attrs: { size: "48" },
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: require("@/assets/img/team-4.jpg"),
                                      alt: "profile-image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "mx-4" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "\n                    text-dark\n                    font-weight-600\n                    text-sm text-decoration-none\n                  ",
                                attrs: { href: "javascript:;" },
                              },
                              [_vm._v("John Snow")]
                            ),
                            _c("small", { staticClass: "d-block text-muted" }, [
                              _vm._v("3 days ago"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "text-end ms-auto" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "font-weight-bolder bg-gradient-primary py-4 px-7",
                                attrs: {
                                  outlined: "",
                                  color: "#fff",
                                  small: "",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-icons-round pe-2",
                                    attrs: { size: "8" },
                                  },
                                  [_vm._v("add")]
                                ),
                                _vm._v(" Follow "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "px-4 py-4" },
                      [
                        _c(
                          "p",
                          { staticClass: "mb-6 text-body font-weight-light" },
                          [
                            _vm._v(
                              " Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you. "
                            ),
                          ]
                        ),
                        _c("v-img", {
                          staticClass: "border-radius-lg shadow-lg",
                          attrs: {
                            src: "https://images.unsplash.com/photo-1578271887552-5ac3a72752bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
                          },
                        }),
                        _c(
                          "v-row",
                          { staticClass: "align-center px-2 mt-6 mb-2" },
                          [
                            _c("v-col", { attrs: { sm: "6" } }, [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                        attrs: { size: "14" },
                                      },
                                      [_vm._v("thumb_up")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-sm text-body me-4" },
                                      [_vm._v("150")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                        attrs: { size: "14" },
                                      },
                                      [_vm._v("mode_comment")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-sm text-body me-4" },
                                      [_vm._v("36")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                        attrs: { size: "14" },
                                      },
                                      [_vm._v("forward")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-sm text-body me-4" },
                                      [_vm._v("12")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("v-col", { attrs: { sm: "6" } }, [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center ms-auto",
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "border border-white ms-n2",
                                          attrs: { size: "24" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/team-5.jpg"),
                                              alt: "Avatar",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "border border-white ms-n2",
                                          attrs: { size: "24" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/team-2.jpg"),
                                              alt: "Avatar",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "border border-white ms-n2",
                                          attrs: { size: "24" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/team-1.jpg"),
                                              alt: "Avatar",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "ps-2 font-weight-bold text-body text-sm",
                                    },
                                    [_vm._v("and 30+ more")]
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mb-1" }, [
                          _c("hr", {
                            staticClass: "horizontal dark mt-1 mb-5",
                          }),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "flex-shrink-0" },
                              [
                                _c(
                                  "v-avatar",
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/img/bruce-mars.jpg"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "flex-grow-1 ms-4" }, [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-h5 text-typo font-weight-bold mt-0 mb-2",
                                },
                                [_vm._v(" Michael Lewis ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-weight-light text-body",
                                },
                                [
                                  _vm._v(
                                    " I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                      attrs: { size: "14" },
                                    },
                                    [_vm._v("thumb_up")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-sm me-2 text-body" },
                                    [_vm._v("3 likes")]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                      attrs: { size: "14" },
                                    },
                                    [_vm._v("share")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-sm me-2 text-body" },
                                    [_vm._v("2 shares")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "d-flex mt-5" }, [
                            _c(
                              "div",
                              { staticClass: "flex-shrink-0" },
                              [
                                _c(
                                  "v-avatar",
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/img/team-5.jpg"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "flex-grow-1 ms-4" }, [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-h5 text-typo font-weight-bold mt-0 mb-2",
                                },
                                [_vm._v(" Jessica Stones ")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-sm font-weight-light text-body",
                                },
                                [
                                  _vm._v(
                                    " Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy. "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                      attrs: { size: "14" },
                                    },
                                    [_vm._v("thumb_up")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-sm me-2 text-body" },
                                    [_vm._v("10 likes")]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "\n                        material-icons-round\n                        me-1\n                        text-body\n                        cursor-pointer\n                      ",
                                      attrs: { size: "14" },
                                    },
                                    [_vm._v("share")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-sm me-2 text-body" },
                                    [_vm._v("1 share")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "d-flex mt-6" }, [
                            _c(
                              "div",
                              { staticClass: "flex-shrink-0" },
                              [
                                _c(
                                  "v-avatar",
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/img/team-4.jpg"),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex-grow-1 ms-4" },
                              [
                                _c("v-textarea", {
                                  staticClass: "input-style mt-0 pt-0",
                                  attrs: {
                                    placeholder: "Write your comment",
                                    color: "#e91e63",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { lg: "4", cols: "12" } },
                [
                  _vm._l(_vm.cards, function (card) {
                    return _c(
                      "v-card",
                      { key: card.title, staticClass: "mb-6" },
                      [
                        _c(
                          "div",
                          { staticClass: "px-4 py-4" },
                          [
                            _c(
                              "v-row",
                              { staticClass: "align-center" },
                              [
                                _c("v-col", { attrs: { cols: "9" } }, [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "\n                    text-h5\n                    font-weight-bold\n                    mb-1\n                    text-gradient text-primary\n                  ",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-decoration-none",
                                          attrs: { href: "javascript:;" },
                                        },
                                        [_vm._v(_vm._s(card.title))]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-end",
                                    attrs: { cols: "3" },
                                  },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          transition: "slide-y-transition",
                                          "offset-y": "",
                                          "offset-x": "",
                                          "min-width": "150",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-secondary",
                                                          attrs: {
                                                            icon: "",
                                                            ripple: false,
                                                            small: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-ellipsis-v"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { staticClass: "pa-2" },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass:
                                                  "list-item-hover-active border-radius-md",
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "pa-0" },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "\n                            text-body-2\n                            ls-0\n                            text-body\n                            font-weight-600\n                            py-2\n                          ",
                                                      },
                                                      [_vm._v(" Edit Team ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass:
                                                  "list-item-hover-active border-radius-md",
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "pa-0" },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "\n                            text-body-2\n                            ls-0\n                            text-body\n                            font-weight-600\n                            py-2\n                          ",
                                                      },
                                                      [_vm._v(" Add Member ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass:
                                                  "list-item-hover-active border-radius-md",
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "pa-0" },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "\n                            text-body-2\n                            ls-0\n                            text-body\n                            font-weight-600\n                            py-2\n                          ",
                                                      },
                                                      [_vm._v(" See Details ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("hr", {
                                              staticClass:
                                                "horizontal dark my-2",
                                            }),
                                            _c(
                                              "v-list-item",
                                              {
                                                staticClass:
                                                  "list-item-hover-active border-radius-md",
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "pa-0" },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "text-danger ls-0 font-weight-600 mb-0",
                                                      },
                                                      [_vm._v(" Remove Team ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "text-body font-weight-light mt-3",
                              },
                              [_vm._v(" " + _vm._s(card.description) + " ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 font-weight-light text-body",
                                  },
                                  [_vm._v("Industry:")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "\n                  border-radius-md\n                  font-weight-bolder\n                  px-3\n                  py-3\n                  badge-font-size\n                  ms-auto\n                  text-body\n                ",
                                    attrs: {
                                      elevation: "0",
                                      small: "",
                                      ripple: false,
                                      height: "21",
                                      color: "#e4e8ed",
                                    },
                                  },
                                  [_vm._v(_vm._s(card.industry))]
                                ),
                              ],
                              1
                            ),
                            _c("hr", { staticClass: "horizontal dark my-4" }),
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 font-weight-light text-body",
                                },
                                [_vm._v("Rating:")]
                              ),
                              _c(
                                "div",
                                { staticClass: "rating ms-auto" },
                                [
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-star text-body"),
                                  ]),
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-star text-body ms-1"),
                                  ]),
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-star text-body ms-1"),
                                  ]),
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-star text-body ms-1"),
                                  ]),
                                  card.rating == "partial"
                                    ? _c("v-icon", { attrs: { size: "16" } }, [
                                        _vm._v(
                                          "fas fa-star-half-alt text-body ms-1"
                                        ),
                                      ])
                                    : _vm._e(),
                                  card.rating == "full"
                                    ? _c("v-icon", { attrs: { size: "16" } }, [
                                        _vm._v("fas fa-star text-body ms-1"),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("hr", { staticClass: "horizontal dark my-4" }),
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 font-weight-light text-body",
                                },
                                [_vm._v("Members:")]
                              ),
                              _c(
                                "span",
                                { staticClass: "avatar-group d-flex ms-auto" },
                                _vm._l(card.avatars, function (avatar) {
                                  return _c(
                                    "v-tooltip",
                                    {
                                      key: avatar.name,
                                      attrs: { bottom: "", color: "#212529" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-avatar",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "border border-white ms-n3",
                                                        attrs: { size: "24" },
                                                      },
                                                      "v-avatar",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: avatar.image,
                                                        alt: "Avatar",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v(_vm._s(avatar.name))])]
                                  )
                                }),
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._l(_vm.brands, function (card, i) {
                    return _c("v-card", { key: i, staticClass: "mb-6" }, [
                      _c("div", { staticClass: "px-4 py-4" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-avatar",
                              [_c("v-img", { attrs: { src: card.img } })],
                              1
                            ),
                            _c("div", { staticClass: "ms-2 my-auto" }, [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "mb-0 text-h6 text-typo font-weight-bold",
                                },
                                [_vm._v(" " + _vm._s(card.title) + " ")]
                              ),
                              _c(
                                "p",
                                { staticClass: "text-xs mb-0 text-body" },
                                [_vm._v(_vm._s(card.hour))]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "text-body font-weight-light mt-3" },
                          [_vm._v(" " + _vm._s(card.description) + " ")]
                        ),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("p", { staticClass: "mb-0 text-body" }, [
                            _c("b", [_vm._v("Meeting ID")]),
                            _vm._v(": "),
                            _c("span", { staticClass: "font-weight-light" }, [
                              _vm._v(_vm._s(card.id)),
                            ]),
                          ]),
                        ]),
                        _c("hr", { staticClass: "horizontal dark my-4" }),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                  font-weight-bolder\n                  btn-success\n                  bg-gradient-default\n                  py-4\n                  px-4\n                ",
                                attrs: {
                                  elevation: 0,
                                  color: "#cb0c9f",
                                  small: "",
                                },
                              },
                              [_vm._v(" Join ")]
                            ),
                            _c(
                              "span",
                              { staticClass: "avatar-group d-flex ms-auto" },
                              _vm._l(card.avatars, function (avatar) {
                                return _c(
                                  "v-tooltip",
                                  {
                                    key: avatar.name,
                                    attrs: { bottom: "", color: "#212529" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-avatar",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "border border-white ms-n3",
                                                      attrs: { size: "24" },
                                                    },
                                                    "v-avatar",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: avatar.image,
                                                      alt: "Avatar",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v(_vm._s(avatar.name))])]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }